export const state = () => ({
  playerStates: {},
  currentAudioId: null,
})

export const getters = {
  // TODO: Change PlayerStates to single Playerstate and check for currentAudioId
  playerStates: (state) => state.playerStates,
  currentAudioId: (state) => state.currentAudioId,
}

export const mutations = {
  SET_PLAYER_STATE(state, { id, playerState }) {
    state.playerStates = {
      ...state.playerStates,
      [id]: playerState,
    }
  },
  SET_CURRENT_AUDIO_ID(state, id) {
    state.currentAudioId = id
  },
}

export const actions = {
  playAudio({ commit, state, dispatch }, { id, rewind = false }) {
    const player = document.getElementById(id)
    commit('SET_PLAYER_STATE', { id, playerState: true })
    commit('SET_CURRENT_AUDIO_ID', id)
    if (player) {
      if (rewind) player.currentTime = 0
      player.play()

      player.addEventListener('ended', () => {
        dispatch('pauseAudio', { id })
      })
      commit('SET_PLAYER_STATE', { id, playerState: true })
      commit('SET_CURRENT_AUDIO_ID', id)
    }
  },
  pauseAudio({ commit }, { id, rewind = false }) {
    const player = document.getElementById(id)
    commit('SET_PLAYER_STATE', { id, playerState: false })
    if (player) {
      player.pause()
      if (rewind) player.currentTime = 0
    }
  },
  toggleAudio({ dispatch, state }, id) {
    if (state.playerStates[id]) {
      dispatch('pauseAudio', { id })
    } else {
      if (state.currentAudioId && state.currentAudioId !== id) {
        dispatch('pauseAudio', { id: state.currentAudioId, rewind: true })
      }
      dispatch('playAudio', { id })
    }
  },
}
