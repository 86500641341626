import experimentsConfig from '@/config/experiments'

export const state = () => ({
  experiments: {},
})

export const getters = {
  experiments: (state) => state.experiments,
  experimentsFetched: (state) => Object.keys(experimentsConfig.activeExperiments).every((experiment) => Object.keys(state.experiments).includes(experiment)),
}

export const mutations = {
  setExperiments: (state, { experiments }) => {
    state.experiments = experiments
  },
}

export const actions = {
  async fetchExperiments({ commit }, { userId }) {
    const activeExperiments = Object.keys(experimentsConfig.activeExperiments)
    const features = activeExperiments.reduce((acc, key) => acc + `&features[]=${key}`, '')

    try {
      const experiments = await this.$bridgeFetch(`/experiments/features?user_id=${userId}${features}`, {
        method: 'GET',
        headers: { Accept: 'application/json' },
      })

      commit('setExperiments', {
        experiments,
      })
    } catch (e) {
      console.error('Error while fetching experiments:', e)
    }
  },
}
