import { PAGE_TYPES } from '@/assets/constants/page-types'

/**
 * @typedef {Object} ExperimentVariants
 * @property {string} A - Variant A
 * @property {string} B - Variant B
 */

/**
 * @typedef {Object} ExperimentConfig
 * @property {ExperimentVariants} variants - The variants for the experiment
 */

/**
 * This file is used to configure serverside A/B tests
 * @property {string[]} activePageTypes - Pages types with experiments in them
 * @property {Object.<string, ExperimentConfig>} activeExperiments - VWO Feature keys for active experiments
 */
const experimentsConfig = {
  // Pages types with experiments in them. We need to fetch the experiments for these pages only to avoid unnecessary
  // registrations of unique users in VWO as we only have a max amount of 50,000 unique users per month.
  activePageTypes: [PAGE_TYPES.EVENT_OVERVIEW],

  // Feature keys for active experiments that should be corresponding to the feature keys in VWO.
  activeExperiments: {
    testFeatureFlag: {
      variants: {
        A: 'C',
        B: 'V2',
      },
    },
  },
}

export default experimentsConfig
