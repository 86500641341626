import { render, staticRenderFns } from "./CardMenuCategory.vue?vue&type=template&id=03fe2c15"
import script from "./CardMenuCategory.vue?vue&type=script&lang=js"
export * from "./CardMenuCategory.vue?vue&type=script&lang=js"


/* normalize component */
import normalizer from "!../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  null,
  null
  
)

export default component.exports

/* nuxt-component-imports */
installComponents(component, {MoleculePicture: require('/home/userapp/components/Molecule/Picture.vue').default,AtomIconArrowRight: require('/home/userapp/components/Atom/Icon/IconArrowRight.vue').default,AtomLink: require('/home/userapp/components/Atom/Link.vue').default})
