import { watch } from '@nuxtjs/composition-api'
import { educationSchoolTypes } from '~/assets/composition/tix'

export const state = () => ({
  order: undefined,
  orderSize: undefined,
  profile: undefined,
  user: undefined,
  tixEnv: 'tix',
  isEducationCustomer: false,
  customerId: null,
  iframe: undefined,
  callback: () => undefined,
  lastMessage: null,
})

export const getters = {
  isEducationCustomer: (state) => state.isEducationCustomer,
  tixEnv: (state) => state.tixEnv,
}

export const mutations = {
  setTixEnv: (state, tixEnv) => {
    switch (tixEnv) {
      case 'tix':
      case 'education':
        state.tixEnv = tixEnv
        break
      default:
        // eslint-disable-next-line no-console
        console.error('invalid tix env var given')
    }
  },
  update(state, { order, profile, user }) {
    const userTagIds = user?.tags.map((tag) => tag.id) ?? []
    // Note that keys will always be converted to String
    const educationTagIds = Object.keys(educationSchoolTypes)

    state.order = order
    state.orderSize = order?.length || 0
    state.profile = profile
    state.user = user
    state.isEducationCustomer = state.tixEnv === 'education' && userTagIds.some((tagId) => educationTagIds.includes(String(tagId)))
  },
  setCustomerId(state, customerId) {
    state.customerId = customerId
  },
  setIframe(state, node) {
    state.iframe = node
  },
  setCallback(state, callback) {
    state.callback = callback
  },
  setLastMessage(state, message) {
    state.lastMessage = message
  },
}

export const actions = {
  call({ commit, state }, { call, reload = true }) {
    commit('setLastMessage', null)
    if (!state.iframe) {
      return Promise.reject(new Error('Tix - iframe not set'))
    }
    const currentTixUrl = state.tixEnv === 'education' ? this.app.$config.edutixSiteUrl : this.app.$config.tixSiteUrl
    if (reload !== false) {
      commit('setCallback', () => {
        state.iframe.contentWindow.postMessage(call, currentTixUrl)
      })
      /** This will trigger the "onload" event of the iframe in {@see TixState.vue}
       * Which will call the callback from above */
      state.iframe.src = `${currentTixUrl}/${this.app.i18n.locale}/itix`
    } else {
      state.iframe.contentWindow.postMessage(call, currentTixUrl)
    }
    return new Promise((resolve, reject) => {
      if (state.lastMessage) {
        resolve(state.lastMessage?.data)
        return
      }
      const timeoutId = setTimeout(() => {
        unwatchHandle()
        reject(new Error('Tix - iframe call timeout'))
      }, 10000)
      const unwatchHandle = watch(
        () => state.lastMessage,
        (messageEvent) => {
          unwatchHandle()
          clearTimeout(timeoutId)
          resolve(messageEvent.data)
        }
      )
    })
  },
  response({ commit, state }, { messageEvent }) {
    const expectedTixOrigin = state.tixEnv === 'education' ? this.app.$config.edutixSiteUrl : this.app.$config.tixSiteUrl
    if (messageEvent.origin !== expectedTixOrigin) {
      return // Ignore non-tix messages
    }
    commit('setLastMessage', messageEvent)
  },
}
